import useWebSocketDataStore from '../Store/Historical_Data_Store';

export const gammaLevelsGetter = function(PineJS) {
  // console.log('[Gamma Levels Indicator] Initializing gammaLevelsGetter');

  return Promise.resolve([
    {
      name: 'SPX GEX Levels',
      metainfo: {
        metainfoVersion: 53,
        id: "SPX_GEX_Levels@tv-basicstudies-1",
        description: "SPX GEX Levels",
        shortDescription: "SPX GEX Levels",
        format: { type: "price" },
        linkedToSeries: true,
        is_price_study: true,
        plots: [
          { id: "call_r1", type: "line" },
          { id: "call_r2", type: "line" },
          { id: "put_r1", type: "line" },
          { id: "put_r2", type: "line" }
        ],
        defaults: {
          styles: {
            call_r1: {
              linestyle: 0,
              linewidth: 1,
              transparency: 0,
              color: "#FFA500",
            },
            call_r2: {
              linestyle: 1,
              linewidth: 1,
              transparency: 0,
              color: "#FFA500",
            },
            put_r1: {
              linestyle: 0,
              linewidth: 1,
              transparency: 0,
              color: "#ee2baa",
            },
            put_r2: {
              linestyle: 1,
              linewidth: 1,
              transparency: 0,
              color: "#ee2baa",
            }
          },
        },
        styles: {
          call_r1: { title: "Gamma Call R1" },
          call_r2: { title: "Gamma Call R2" },
          put_r1: { title: "Gamma Put R1" },
          put_r2: { title: "Gamma Put R2" }
        },
        inputs: [],
      },
      constructor: function() {
        this._debugMode = true;

        this.init = function(ctx) {
          this._context = ctx;
        };

        this.main = function(ctx) {
          try {
            const store = useWebSocketDataStore.getState();
            const { historicalOptionsData, replayIndex } = store;

            // Get the current point in time based on replay index
            const currentOptions = historicalOptionsData.filter(opt => {
              const pointIndex = store.historicalGreekData.findIndex(d => d.time === opt.time);
              return pointIndex <= replayIndex;
            });


            // Get the most recent set of options
            const latestTime = Math.max(...currentOptions.map(opt => opt.time));
            const latestOptions = currentOptions.filter(opt => opt.time === latestTime);

            // Get current levels
            const callR1 = latestOptions.find(opt => opt.option_type === 'call' && opt.rank === 1)?.strike || NaN;
            const callR2 = latestOptions.find(opt => opt.option_type === 'call' && opt.rank === 2)?.strike || NaN;
            const putR1 = latestOptions.find(opt => opt.option_type === 'put' && opt.rank === 1)?.strike || NaN;
            const putR2 = latestOptions.find(opt => opt.option_type === 'put' && opt.rank === 2)?.strike || NaN;

            // Return the same levels for any point in time
            return [callR1, callR2, putR1, putR2];
          } catch (err) {
            console.error('[Gamma Levels] Error in main:', err);
            return [NaN, NaN, NaN, NaN];
          }
        };

        this.destroy = function() {
          // No cleanup needed
        };
      }
    }
  ]);
};




// import useWebSocketDataStore from '../Store/Historical_Data_Store';
//
// export const gammaLevelsGetter = function(PineJS) {
//   console.log('[Gamma Levels Indicator] Initializing gammaLevelsGetter');
//
//   return Promise.resolve([
//     {
//       name: 'SPX GEX Levels',
//       metainfo: {
//         metainfoVersion: 53,
//         id: "SPX_GEX_Levels@tv-basicstudies-1",
//         description: "SPX GEX Levels",
//         shortDescription: "SPX GEX Levels",
//         format: { type: "price" },
//         linkedToSeries: true,
//         is_price_study: true,
//         plots: [
//           { id: "call_r1", type: "line" },
//           { id: "call_r2", type: "line" },
//           { id: "put_r1", type: "line" },
//           { id: "put_r2", type: "line" }
//         ],
//         defaults: {
//           styles: {
//             call_r1: {
//               linestyle: 0,
//               linewidth: 1,
//               transparency: 0,
//               color: "#FFA500",
//             },
//             call_r2: {
//               linestyle: 1,
//               linewidth: 1,
//               transparency: 0,
//               color: "#FFA500",
//             },
//             put_r1: {
//               linestyle: 0,
//               linewidth: 1,
//               transparency: 0,
//               color: "#800080",
//             },
//             put_r2: {
//               linestyle: 1,
//               linewidth: 1,
//               transparency: 0,
//               color: "#800080",
//             }
//           },
//         },
//         styles: {
//           call_r1: { title: "Gamma Call R1" },
//           call_r2: { title: "Gamma Call R2" },
//           put_r1: { title: "Gamma Put R1" },
//           put_r2: { title: "Gamma Put R2" }
//         },
//         inputs: [],
//       },
//       constructor: function() {
//         this._debugMode = true;
//
//         this.init = function(ctx) {
//           this._context = ctx;
//         };
//
//         this.main = function(ctx) {
//           try {
//             const store = useWebSocketDataStore.getState();
//             const { historicalOptionsData, replayIndex } = store;
//
//             // Get the current point in time based on replay index
//             const currentOptions = historicalOptionsData.filter(opt => {
//               const pointIndex = store.historicalGreekData.findIndex(d => d.time === opt.time);
//               return pointIndex <= replayIndex;
//             });
//
//
//             // Get the most recent set of options
//             const latestTime = Math.max(...currentOptions.map(opt => opt.time));
//             const latestOptions = currentOptions.filter(opt => opt.time === latestTime);
//
//             // Get current levels
//             const callR1 = latestOptions.find(opt => opt.option_type === 'call' && opt.rank === 1)?.strike || NaN;
//             const callR2 = latestOptions.find(opt => opt.option_type === 'call' && opt.rank === 2)?.strike || NaN;
//             const putR1 = latestOptions.find(opt => opt.option_type === 'put' && opt.rank === 1)?.strike || NaN;
//             const putR2 = latestOptions.find(opt => opt.option_type === 'put' && opt.rank === 2)?.strike || NaN;
//
//             // Return the same levels for any point in time
//             return [callR1, callR2, putR1, putR2];
//           } catch (err) {
//             console.error('[Gamma Levels] Error in main:', err);
//             return [NaN, NaN, NaN, NaN];
//           }
//         };
//
//         this.destroy = function() {
//           // No cleanup needed
//         };
//       }
//     }
//   ]);
// };








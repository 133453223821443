import React, { useEffect } from 'react';
import useWebSocketDataStore from "../Store/Historical_Data_Store.js";
import { processHistoricalData } from './HistoricalDataAnalyzer';

const HistoricalClient = () => {
  const {
    setSendHistoricalDataRequest,
    selectedDate
  } = useWebSocketDataStore();

  useEffect(() => {
    // console.log('HistoricalClient mounting...');
    let socket;

    try {
      const wsURL = 'wss://react-historical-websocket-pro-b38bf068da0e.herokuapp.com';
      // console.log('Attempting to connect to WebSocket at:', wsURL);

      socket = new WebSocket(wsURL);

      socket.addEventListener('open', () => {
        // console.log('WebSocket connection established successfully');

        const sendRequest = (date) => {
          // console.log('Attempting to send request for date:', date);
          if (socket.readyState === WebSocket.OPEN) {
            const request = {
              type: 'historical-data',
              date
            };
            // console.log('Sending request:', request);
            socket.send(JSON.stringify(request));
            // console.log('Request sent successfully');
          } else {
            console.warn('Socket not open, readyState:', socket.readyState);
          }
        };

        setSendHistoricalDataRequest(sendRequest);

        if (selectedDate) {
          // console.log('Initial request for selected date:', selectedDate);
          sendRequest(selectedDate);
        } else {
          // console.log('No selected date available for initial request');
        }
      });

      socket.addEventListener('error', (error) => {
        // console.error('WebSocket error occurred:', error);
      });

      socket.addEventListener('message', (event) => {
        // console.log('Received WebSocket message');
        try {
          const message = JSON.parse(event.data);
          // console.log('Parsed message:', message);

          if (message.greekData && Array.isArray(message.greekData)) {
            // console.log('Passing message to analyzer...');
            processHistoricalData(message);  // Let analyzer handle store updates
          } else {
            console.log('Message did not contain expected Greek data array');
          }
        } catch (error) {
          console.error('Error processing message:', error);
        }
      });

    } catch (error) {
      console.error('Error setting up WebSocket:', error);
    }

    return () => {
      if (socket) {
        // console.log('Closing WebSocket connection...');
        socket.close();
      }
    };
  }, [setSendHistoricalDataRequest, selectedDate]);

  return null;
};

export default HistoricalClient;
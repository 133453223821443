import useWebSocketDataStore from "../Store/Historical_Data_Store.js";
import moment from 'moment-timezone';
import _ from 'lodash';


const formatTimeForTradingView = (timeStr) => {
    // console.log('Converting time:', timeStr);
    // First round to minute by setting seconds and milliseconds to 0
    const timestamp = moment(timeStr)
        .add(5, 'hours')           // <-- This is the key line you requested
        .seconds(0)
        .milliseconds(0)
        .valueOf(); // Get timestamp in milliseconds
    // console.log('Converted to Unix timestamp (ms, rounded to minute):', timestamp);
    return timestamp;
};

// Process options data to get top 2 calls and puts for each timestamp
const processOptionsForTimestamp = (options) => {
    // console.log('\nProcessing options for timestamp. Total options:', options.length);

    const calls = options.filter(opt => opt.option_type === 'call');
    const puts = options.filter(opt => opt.option_type === 'put');

    // console.log('Found calls:', calls);
    // console.log('Found puts:', puts);

    const topCalls = calls.slice(0, 2)
        .map((opt, index) => ({
            time: opt.timestamp,
            option_type: 'call',
            strike: opt.strike,
            rank: index + 1
        }));

    const topPuts = puts.slice(0, 2)
        .map((opt, index) => ({
            time: opt.timestamp,
            option_type: 'put',
            strike: opt.strike,
            rank: index + 1
        }));

    // console.log('\nTop 2 Calls:', JSON.stringify(topCalls, null, 2));
    // console.log('Top 2 Puts:', JSON.stringify(topPuts, null, 2));

    return [...topCalls, ...topPuts];
};

// Smoothing filter for trend strength calculations
const applySmoothingFilter = (data, alpha = 0.2) => {
    // console.log('\nApplying smoothing filter to data length:', data.length);
    const a1 = Math.exp(-1.414 * Math.PI * alpha);
    const b1 = 2 * a1 * Math.cos(1.414 * Math.PI * alpha);
    const c2 = b1;
    const c3 = -a1 * a1;
    const c1 = 1 - c2 - c3;

    const smoothedData = [];
    smoothedData[0] = 0;
    smoothedData[1] = data[1];

    for (let i = 2; i < data.length; i++) {
        smoothedData[i] = c1 * data[i] + c2 * smoothedData[i - 1] + c3 * smoothedData[i - 2];
    }

    // console.log('Smoothing complete. Sample of first smoothed value:', smoothedData[0]);
    return smoothedData;
};

// Calculate GEX trend strength
const calculateGEXTrendStrength = (data) => {
    // console.log('\n--- Starting GEX Trend Strength Calculation ---');
    // console.log('Input data length:', data?.length || 0);

    if (!data || data.length === 0) return [];

    const trendStrengths = [];

    trendStrengths.push({
        time: data[0]?.time || 0,
        value: 0,
        trend: 'neutral'
    });

    for (let i = 1; i < data.length; i++) {
        const callGEX = data[i].total_call_volume_gamma;
        const putGEX = data[i].total_put_volume_gamma;

        if (callGEX === 0 && putGEX === 0) {
            trendStrengths.push({
                time: data[i].time,
                value: 0,
                trend: 'neutral'
            });
        } else if (callGEX > putGEX) {
            const strength = (callGEX - putGEX) / (callGEX + putGEX || 1);
            trendStrengths.push({
                time: data[i].time,
                value: Math.max(strength, 0),
                trend: 'call'
            });
        } else {
            const strength = (putGEX - callGEX) / (callGEX + putGEX || 1);
            trendStrengths.push({
                time: data[i].time,
                value: Math.max(strength, 0),
                trend: 'put'
            });
        }
    }

    // console.log('Raw trend strengths calculated. Sample:', trendStrengths[0]);
    const smoothedStrengths = applySmoothingFilter(trendStrengths.map(d => d.value));
    // console.log('Smoothing applied to trend strengths');

    const result = trendStrengths.map((d, index) => ({
        time: d.time,
        value: smoothedStrengths[index],
        trend: d.trend
    }));

    // console.log('GEX Trend Strength calculation complete. Sample:', result[0]);
    return result;
};

// Calculate Dynamic Levels from bottom 5 strikes
const calculateDynamicLevels = (options, greekData) => {
    if (!options || options.length === 0) return [];

    // Group by timestamp
    const groupedByTimestamp = _.groupBy(options, 'timestamp');

    return Object.entries(groupedByTimestamp).map(([timestamp, timeOptions]) => {
        // Split into calls and puts
        const calls = timeOptions.filter(opt => opt.option_type === 'call');
        const puts = timeOptions.filter(opt => opt.option_type === 'put');

        // Get bottom 5 strikes (indices 5-9)
        const bottomCalls = calls.slice(5, 10);
        const bottomPuts = puts.slice(5, 10);

        const time = formatTimeForTradingView(timestamp);
        const ohlcData = greekData?.find(d => formatTimeForTradingView(d.time) === time);

        // If we have OHLC data, filter strikes based on high/low values
        let validBottomCalls = bottomCalls;
        let validBottomPuts = bottomPuts;

        if (ohlcData?.high) {
            validBottomCalls = bottomCalls.filter(call => call.strike > ohlcData.high);
        }
        if (ohlcData?.low) {
            validBottomPuts = bottomPuts.filter(put => put.strike < ohlcData.low);
        }

        // Use filtered arrays if they have values, otherwise fall back to original arrays
        const ddh = (validBottomCalls.length > 0 ? validBottomCalls : bottomCalls)
            .reduce((max, current) => current.volume > max.volume ? current : max,
                   validBottomCalls.length > 0 ? validBottomCalls[0] : bottomCalls[0]);

        const ddl = (validBottomPuts.length > 0 ? validBottomPuts : bottomPuts)
            .reduce((max, current) => current.volume > max.volume ? current : max,
                   validBottomPuts.length > 0 ? validBottomPuts[0] : bottomPuts[0]);

        return {
            time,
            ddh: {
                strike: ddh.strike,
                volume: ddh.volume,
                option_type: 'call'
            },
            ddl: {
                strike: ddl.strike,
                volume: ddl.volume,
                option_type: 'put'
            }
        };
    });
};


// const calculateDynamicLevels = (options) => {
//     if (!options || options.length === 0) return [];
//
//     // Group by timestamp
//     const groupedByTimestamp = _.groupBy(options, 'timestamp');
//
//     return Object.entries(groupedByTimestamp).map(([timestamp, timeOptions]) => {
//         // Split into calls and puts
//         const calls = timeOptions.filter(opt => opt.option_type === 'call');
//         const puts = timeOptions.filter(opt => opt.option_type === 'put');
//
//         // Get bottom 5 strikes (indices 5-9)
//         const bottomCalls = calls.slice(5, 10);
//         const bottomPuts = puts.slice(5, 10);
//
//         // Find highest volume from bottom 5
//         const ddh = bottomCalls.reduce((max, current) =>
//             current.volume > max.volume ? current : max, bottomCalls[0]);
//
//         const ddl = bottomPuts.reduce((max, current) =>
//             current.volume > max.volume ? current : max, bottomPuts[0]);
//
//         return {
//             time: formatTimeForTradingView(timestamp),
//             ddh: {
//                 strike: ddh.strike,
//                 volume: ddh.volume,
//                 option_type: 'call'
//             },
//             ddl: {
//                 strike: ddl.strike,
//                 volume: ddl.volume,
//                 option_type: 'put'
//             }
//         };
//     });
// };

export const processHistoricalData = (message) => {
    // console.log('\n=== Starting Historical Data Processing ===');
    let processedGreekData = [];
    let processedOptionsData = [];

    // Get store actions
    const store = useWebSocketDataStore.getState();
    const { setHistoricalData } = store;

    // Process Greek data
    if (message.greekData && Array.isArray(message.greekData)) {
        processedGreekData = message.greekData.map(item => {
            const tradingViewTime = formatTimeForTradingView(item.time);
            const total_theta = Math.abs(item.total_call_volume_theta) - Math.abs(item.total_put_volume_theta);
            const total_delta = Math.abs(item.total_call_volume_delta) - Math.abs(item.total_put_volume_delta);
            const total_vega = Math.abs(item.total_call_volume_vega) - Math.abs(item.total_put_volume_vega);

            return {
                ...item,
                time: tradingViewTime,
                total_theta,
                total_delta,
                total_vega,
                source: "GREEKDATA",
                greeks: {
                    call: {
                        gamma: item.total_call_volume_gamma,
                        theta: item.total_call_volume_theta,
                        vega: item.total_call_volume_vega,
                        delta: item.total_call_volume_delta
                    },
                    put: {
                        gamma: item.total_put_volume_gamma,
                        theta: item.total_put_volume_theta,
                        vega: item.total_put_volume_vega,
                        delta: item.total_put_volume_delta
                    }
                }
            };
        });

        // Remove duplicates and sort
        const initialLength = processedGreekData.length;
        processedGreekData = processedGreekData
            .reduce((acc, curr) => {
                const duplicate = acc.find(item => item.time === curr.time);
                if (!duplicate) acc.push(curr);
                return acc;
            }, [])
            .sort((a, b) => a.time - b.time);

        // Calculate GEX trend strength
        const trendStrengthData = calculateGEXTrendStrength(processedGreekData);

        // Calculate Dynamic Levels if we have options data
        if (message.optionsData && Array.isArray(message.optionsData)) {
            // console.log('\n--- Processing Dynamic Levels ---');
            const dynamicLevels = calculateDynamicLevels(message.optionsData, message.greekData);
            // const dynamicLevels = calculateDynamicLevels(message.optionsData);

            // Log sample of dynamic levels
            // console.log('Sample Dynamic Level:', {
            //     time: new Date(dynamicLevels[0]?.time).toISOString(),
            //     ddh: dynamicLevels[0]?.ddh,
            //     ddl: dynamicLevels[0]?.ddl
            // });

            // Merge all data together
            processedGreekData = processedGreekData.map((item, index) => {
                const levelData = dynamicLevels.find(level => level.time === item.time);
                return {
                    ...item,
                    gexTrendStrength: {
                        value: trendStrengthData[index].value,
                        trend: trendStrengthData[index].trend
                    },
                    dynamicLevels: levelData || null
                };
            });

            // Log merged data sample
            // console.log('\nSample processed entry with dynamic levels:', {
            //     time: new Date(processedGreekData[0]?.time).toISOString(),
            //     dynamicLevels: processedGreekData[0]?.dynamicLevels,
            //     gexTrendStrength: processedGreekData[0]?.gexTrendStrength
            // });
        }
    }

    // Process options data
    if (message.optionsData && Array.isArray(message.optionsData)) {
        // Group by timestamp
        const groupedByTimestamp = _.groupBy(message.optionsData, 'timestamp');

        // Process each timestamp group
        processedOptionsData = Object.entries(groupedByTimestamp).flatMap(([timestamp, options]) => {
            const tradingViewTime = formatTimeForTradingView(timestamp);
            return processOptionsForTimestamp(options).map(opt => ({
                ...opt,
                time: tradingViewTime,
                source: "OPTIONSDATA"
            }));
        });

        // Sort final result
        processedOptionsData.sort((a, b) => a.time - b.time);
    }

    // Update store with processed data
    setHistoricalData({
        processedGreekData,
        processedOptionsData
    });

    // Still return the processed data for any other uses
    return {
        processedGreekData,
        processedOptionsData
    };
};

export default processHistoricalData;



import useWebSocketDataStore from '../Store/Historical_Data_Store';

export const gexTrendStrengthGetter = function(PineJS) {
  // console.log('[GEX Trend Strength Indicator] Initializing gexTrendStrengthGetter');

  return Promise.resolve([
    {
      name: 'SPX_GEX_Trend_Strength',
      metainfo: {
        metainfoVersion: 53,
        id: "SPX_GEX_Trend_Strength@tv-basicstudies-1",
        description: "SPX GEX Trend Strength",
        shortDescription: "SPX GTS",
        linkedToSeries: true,
        is_price_study: false,
        format: { type: "price" },
        plots: [
          {
            id: "call_strength",
            type: "line",
            title: "Call Trend",
            trackPrice: false,
            transparency: 70,
            fillGaps: true
          },
          {
            id: "put_strength",
            type: "line",
            title: "Put Trend",
            trackPrice: false,
            transparency: 70,
            fillGaps: true
          },
        ],
        defaults: {
          styles: {
            call_strength: {
              linestyle: 0,
              linewidth: 2,
              plottype: 8,
              trackPrice: false,
              transparency: 80,
              visible: true,
              color: "#02ccfd",  // Light blue
              areaStyle: 1
            },
            put_strength: {
              linestyle: 0,
              linewidth: 2,
              plottype: 8,
              trackPrice: false,
              transparency: 80,
              visible: true,
              color: "#ff0000",  // Red
              areaStyle: 1
            },
          },
          precision: 2,
          inputs: {}
        },
        styles: {
          call_strength: {
            title: "Call Trend",
            areaStyle: 1,
            histogramBase: 0
          },
          put_strength: {
            title: "Put Trend",
            areaStyle: 1,
            histogramBase: 0
          },
        },
        inputs: []
      },
      constructor: function() {
        // Store the last known values so we can return them instead of null
        this._lastCallValue = 0;
        this._lastPutValue = 0;

        this.main = function(ctx) {
          const time = ctx.symbol.time;
          if (!time || isNaN(time)) {
            return [null, null, null];
          }

          const store = useWebSocketDataStore.getState();
          const historicalData = store.historicalGreekData;
          const replayIndex = store.replayIndex;

          if (historicalData.length === 0) {
            return [null, null, null];
          }

          // Find the data point for the current timestamp
          const dataPoint = historicalData.find(d => d.time === time);
          if (!dataPoint) {
            return [null, null, null];
          }

          // If we're within the replay index range, return the value
          const pointIndex = historicalData.indexOf(dataPoint);
          if (pointIndex <= replayIndex) {
            const trendStrength = dataPoint.gexTrendStrength;
            if (!trendStrength) {
              return [null, null, null];
            }

            const value = trendStrength.value;
            const trendType = trendStrength.trend;

            // If the trend is call, update the last known call value
            if (trendType === 'call') {
              this._lastCallValue = value;
              return [
                value,  // call_strength (active trend)
                0,      // put_strength (non-active trend set to 0)
              ];
            }
            // Otherwise, the trend is put, update last known put value
            else {
              this._lastPutValue = value;
              return [
                0,      // call_strength (non-active trend set to 0)
                value,  // put_strength (active trend)
              ];
            }
          }

          return [null, null, null];
        };
      }
    }
  ]);
};












import useWebSocketDataStore from '../Store/Historical_Data_Store';
import useStreamingStore from '../Store/Historical_Streaming_Store';

class CustomDataFeed {
    constructor({ initialData, isReplaying = false, currentDataIndex = 0 }) {
        this.subscribers = {};
        this.storeUnsubscribers = {};
        this.lastBars = {};
        this.isReplaying = isReplaying;
        this.currentReplayIndex = currentDataIndex;
        this.replayData = initialData || null;
        this.realtimeCallbacks = new Set();
    }

    onReady(callback) {
        setTimeout(() => {
            callback({
                supported_resolutions: ['1'],
                supports_marks: false,
                supports_timescale_marks: false,
                // supports_time: true,
                timezone: "America/New_York"
            });
        }, 0);
    }

    resolveSymbol(symbolName, onResolveCallback, onErrorCallback) {
        setTimeout(() => {
            onResolveCallback({
                name: symbolName,
                description: 'SPX Index',
                type: 'index',
                session: '0930-1600',
                timezone: "America/New_York",
                exchange: 'INDEX',
                minmov: 1,
                pricescale: 100,
                has_intraday: true,
                has_daily: false,
                has_weekly_and_monthly: false,
                supported_resolutions: ['1'],
                data_status: 'streaming',
            });
        }, 0);
    }

    getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
        try {
            const historicalData = useWebSocketDataStore.getState().historicalGreekData;
            // console.log('[getBars] Called with params:', {
            //     firstDataRequest: periodParams.firstDataRequest,
            //     isReplaying: this.isReplaying,
            //     historicalDataLength: historicalData?.length,
            //     firstHistoricalPoint: historicalData?.[0]?.time ? new Date(historicalData[0].time) : 'No data',
            //     currentReplayIndex: this.currentReplayIndex
            // });

            if (!historicalData || historicalData.length === 0) {
                // console.log('[CustomDataFeed] No historical data available');
                onHistoryCallback([], { noData: true });
                return;
            }

            // Handle first data request
            if (periodParams.firstDataRequest) {
                // console.log('[getBars] Handling first data request:', {
                //     isReplayMode: this.isReplaying,
                //     currentIndex: this.currentReplayIndex
                // });

                if (this.isReplaying) {
                    const firstBar = {
                        time: historicalData[0].time,
                        open: parseFloat(historicalData[0].open),
                        high: parseFloat(historicalData[0].high),
                        low: parseFloat(historicalData[0].low),
                        close: parseFloat(historicalData[0].close)
                    };

                    // console.log('[getBars] Replay first bar:', {
                    //     time: new Date(firstBar.time),
                    //     open: firstBar.open,
                    //     close: firstBar.close
                    // });

                    this.lastBars['SPX'] = firstBar;
                    onHistoryCallback([firstBar]);
                    return;
                } else {
                    const currentPoint = useStreamingStore.getState().currentGreekData;
                    // console.log('[getBars] Non-replay current point:', {
                    //     time: currentPoint?.time ? new Date(currentPoint.time) : 'No time',
                    //     hasData: !!currentPoint
                    // });

                    if (!currentPoint) {
                        onHistoryCallback([], { noData: true });
                        return;
                    }

                    const initialBar = {
                        time: currentPoint.time,
                        open: parseFloat(currentPoint.open),
                        high: parseFloat(currentPoint.high),
                        low: parseFloat(currentPoint.low),
                        close: parseFloat(currentPoint.close)
                    };

                    this.lastBars['SPX'] = initialBar;
                    onHistoryCallback([initialBar]);
                    return;
                }
            }

            // For replay mode, return data up to current index
            if (this.isReplaying) {
                const bars = historicalData
                    .slice(0, this.currentReplayIndex + 1)
                    .map(entry => ({
                        time: entry.time,
                        open: parseFloat(entry.open),
                        high: parseFloat(entry.high),
                        low: parseFloat(entry.low),
                        close: parseFloat(entry.close)
                    }));

                // console.log('[getBars] Replay bars:', {
                //     barsLength: bars.length,
                //     firstBarTime: bars[0]?.time ? new Date(bars[0].time) : 'No bars',
                //     lastBarTime: bars[bars.length - 1]?.time ? new Date(bars[bars.length - 1].time) : 'No bars'
                // });

                if (bars.length > 0) {
                    this.lastBars['SPX'] = bars[bars.length - 1];
                }

                onHistoryCallback(bars);
                return;
            }

            // console.log('[getBars] Non-replay, no data returned');
            onHistoryCallback([], { noData: true });
        } catch (error) {
            console.error('[CustomDataFeed] Error in getBars:', error);
            onErrorCallback(error);
        }
    }

    updateData(newData) {
        if (!newData || !this.isReplaying) return;

        // console.log('[updateData] New data point:', {
        //     time: new Date(newData.time),
        //     isReplaying: this.isReplaying
        // });

        const bar = {
            time: newData.time,
            open: parseFloat(newData.open),
            high: parseFloat(newData.high),
            low: parseFloat(newData.low),
            close: parseFloat(newData.close)
        };

        this.lastBars['SPX'] = bar;
        this.realtimeCallbacks.forEach(callback => callback(bar));
    }

    startReplay() {
        this.isReplaying = true;
        this.currentReplayIndex = 0;
    }

    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID) {
        this.realtimeCallbacks.add(onRealtimeCallback);

        if (!this.storeUnsubscribers[subscriberUID] && !this.isReplaying) {
            const unsubscribe = useStreamingStore.subscribe((state) => {
                const currentGreekData = state.currentGreekData;
                if (!currentGreekData || !currentGreekData.time) return;

                const bar = {
                    time: currentGreekData.time,
                    open: parseFloat(currentGreekData.open),
                    high: parseFloat(currentGreekData.high),
                    low: parseFloat(currentGreekData.low),
                    close: parseFloat(currentGreekData.close)
                };

                const lastBar = this.lastBars['SPX'];
                if (!lastBar || bar.time > lastBar.time) {
                    onRealtimeCallback(bar);
                    this.lastBars['SPX'] = bar;
                }
            });

            this.storeUnsubscribers[subscriberUID] = unsubscribe;
        }
    }

    unsubscribeBars(subscriberUID) {
        if (this.storeUnsubscribers[subscriberUID]) {
            this.storeUnsubscribers[subscriberUID]();
            delete this.storeUnsubscribers[subscriberUID];
        }
        this.realtimeCallbacks.delete(this.subscribers[subscriberUID]);
    }

    resetReplay() {
    this.isReplaying = false;
    this.currentReplayIndex = 0;
    this.lastBars = {};
    this.realtimeCallbacks.clear();
    }

    destroy() {
        Object.keys(this.storeUnsubscribers).forEach(uid => {
            if (this.storeUnsubscribers[uid]) {
                this.storeUnsubscribers[uid]();
            }
        });
        this.storeUnsubscribers = {};
        this.lastBars = {};
        this.realtimeCallbacks.clear();
    }
}

export default CustomDataFeed;



// import useWebSocketDataStore from '../Store/Historical_Data_Store';
// import useStreamingStore from '../Store/Historical_Streaming_Store';
//
// class CustomDataFeed {
//     constructor({ initialData, isReplaying = false, currentDataIndex = 0 }) {
//         this.subscribers = {};
//         this.storeUnsubscribers = {};
//         this.lastBars = {};
//         this.isReplaying = isReplaying;
//         this.currentReplayIndex = currentDataIndex;
//         this.replayData = initialData || null;
//         this.realtimeCallbacks = new Set();
//     }
//
//     onReady(callback) {
//         setTimeout(() => {
//             callback({
//                 supported_resolutions: ['1'],
//                 supports_marks: false,
//                 supports_timescale_marks: false,
//                 supports_time: true,
//                 timezone: "America/New_York"
//             });
//         }, 0);
//     }
//
//     resolveSymbol(symbolName, onResolveCallback, onErrorCallback) {
//         setTimeout(() => {
//             onResolveCallback({
//                 name: symbolName,
//                 description: 'SPX Index',
//                 type: 'index',
//                 session: '0930-1600',
//                 timezone: "America/New_York",
//                 exchange: 'INDEX',
//                 minmov: 1,
//                 pricescale: 100,
//                 has_intraday: true,
//                 has_daily: false,
//                 has_weekly_and_monthly: false,
//                 supported_resolutions: ['1'],
//                 data_status: 'streaming',
//             });
//         }, 0);
//     }
//
//     adjustTimestamp(timestamp) {
//         // The incoming timestamps are already correct (EST market time)
//         // No adjustment needed as TradingView will handle the display
//         return timestamp;
//     }
//
//     getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
//         try {
//             const historicalData = useWebSocketDataStore.getState().historicalGreekData;
//
//             if (!historicalData || historicalData.length === 0) {
//                 console.log('[CustomDataFeed] No historical data available');
//                 onHistoryCallback([], { noData: true });
//                 return;
//             }
//
//             if (periodParams.firstDataRequest) {
//                 const currentPoint = useStreamingStore.getState().currentGreekData;
//
//                 if (!currentPoint) {
//                     onHistoryCallback([], { noData: true });
//                     return;
//                 }
//
//                 const initialBar = {
//                     time: this.adjustTimestamp(currentPoint.time),
//                     open: parseFloat(currentPoint.open),
//                     high: parseFloat(currentPoint.high),
//                     low: parseFloat(currentPoint.low),
//                     close: parseFloat(currentPoint.close)
//                 };
//
//                 this.lastBars['SPX'] = initialBar;
//                 onHistoryCallback([initialBar]);
//                 return;
//             }
//
//             const { from, to } = periodParams;
//             const fromMs = from * 1000;
//             const toMs = to * 1000;
//
//             console.log('[CustomDataFeed] Time range request:', {
//                 from: new Date(fromMs).toISOString(),
//                 to: new Date(toMs).toISOString()
//             });
//
//             const filteredData = historicalData
//                 .filter(entry => {
//                     const entryTime = entry.time;
//                     const withinRange = entryTime >= fromMs && entryTime <= toMs;
//                     if (withinRange) {
//                         console.log('[CustomDataFeed] Including data point:', {
//                             original: new Date(entryTime).toISOString(),
//                             adjusted: new Date(this.adjustTimestamp(entryTime)).toISOString()
//                         });
//                     }
//                     return withinRange;
//                 });
//
//             if (filteredData.length === 0) {
//                 onHistoryCallback([], { noData: true });
//                 return;
//             }
//
//             const bars = filteredData.map(entry => ({
//                 time: this.adjustTimestamp(entry.time),
//                 open: parseFloat(entry.open),
//                 high: parseFloat(entry.high),
//                 low: parseFloat(entry.low),
//                 close: parseFloat(entry.close)
//             }));
//
//             if (bars.length > 0) {
//                 this.lastBars['SPX'] = bars[bars.length - 1];
//             }
//
//             onHistoryCallback(bars);
//         } catch (error) {
//             console.error('[CustomDataFeed] Error in getBars:', error);
//             onErrorCallback(error);
//         }
//     }
//
//     updateData(newData) {
//         if (!newData) return;
//
//         const bar = {
//             time: this.adjustTimestamp(newData.time),
//             open: parseFloat(newData.open),
//             high: parseFloat(newData.high),
//             low: parseFloat(newData.low),
//             close: parseFloat(newData.close)
//         };
//
//         // Update last bar
//         this.lastBars['SPX'] = bar;
//
//         // Notify callbacks
//         this.realtimeCallbacks.forEach(callback => {
//             callback(bar);
//         });
//     }
//
//     startReplay() {
//         this.isReplaying = true;
//         this.currentReplayIndex = 0;
//     }
//
//     advanceReplay() {
//         if (this.isReplaying && this.replayData) {
//             this.currentReplayIndex = Math.min(
//                 this.currentReplayIndex + 1,
//                 this.replayData.length - 1
//             );
//             return this.currentReplayIndex < this.replayData.length - 1;
//         }
//         return false;
//     }
//
//     stopReplay() {
//         this.isReplaying = false;
//         this.currentReplayIndex = 0;
//     }
//
//     subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID) {
//         // Add the callback to our set of callbacks
//         this.realtimeCallbacks.add(onRealtimeCallback);
//
//         if (!this.storeUnsubscribers[subscriberUID]) {
//             const unsubscribe = useStreamingStore.subscribe((state) => {
//                 const currentGreekData = state.currentGreekData;
//                 if (!currentGreekData || !currentGreekData.time) return;
//
//                 const bar = {
//                     time: this.adjustTimestamp(currentGreekData.time),
//                     open: parseFloat(currentGreekData.open),
//                     high: parseFloat(currentGreekData.high),
//                     low: parseFloat(currentGreekData.low),
//                     close: parseFloat(currentGreekData.close)
//                 };
//
//                 const lastBar = this.lastBars['SPX'];
//
//                 if (!lastBar || bar.time > lastBar.time) {
//                     console.log('[CustomDataFeed] New bar:', {
//                         time: new Date(bar.time).toISOString(),
//                         ohlc: {
//                             open: bar.open,
//                             high: bar.high,
//                             low: bar.low,
//                             close: bar.close
//                         }
//                     });
//
//                     onRealtimeCallback(bar);
//                     this.lastBars['SPX'] = bar;
//                 }
//             });
//
//             this.storeUnsubscribers[subscriberUID] = unsubscribe;
//         }
//     }
//
//     unsubscribeBars(subscriberUID) {
//         if (this.storeUnsubscribers[subscriberUID]) {
//             this.storeUnsubscribers[subscriberUID]();
//             delete this.storeUnsubscribers[subscriberUID];
//         }
//     }
//
//     destroy() {
//         this.stopReplay();
//         Object.keys(this.storeUnsubscribers).forEach(uid => {
//             if (this.storeUnsubscribers[uid]) {
//                 this.storeUnsubscribers[uid]();
//             }
//         });
//         this.storeUnsubscribers = {};
//         this.lastBars = {};
//         this.realtimeCallbacks.clear();
//     }
// }
//
// export default CustomDataFeed;








































import { Calendar, Play, Pause, FastForward, Rewind } from 'lucide-react';
import useWebSocketDataStore from '../Store/Historical_Data_Store';
import { useEffect } from 'react';

const DateSelector = ({ onDateChange }) => {
  const DEFAULT_DATE = '2024-01-02';

  const {
    sendHistoricalDataRequest,
    isPlaying,
    replaySpeed,
    setIsPlaying,
    setReplaySpeed,
    resetReplay,
    selectedDate,
    setSelectedDate
  } = useWebSocketDataStore();

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(DEFAULT_DATE);
      if (sendHistoricalDataRequest) {
        sendHistoricalDataRequest(DEFAULT_DATE);
      }
    }
  }, [sendHistoricalDataRequest, selectedDate, setSelectedDate]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
    resetReplay();

    if (sendHistoricalDataRequest) {
      sendHistoricalDataRequest(newDate);
    }

    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  const handleSpeedChange = (change) => {
    setReplaySpeed((prev) => {
      const newSpeed = prev + change;
      // Limit between 10ms (fastest) and 2000ms (slowest)
      return Math.max(25, Math.min(3000, newSpeed));
    });
  };

  const handleSpeedInput = (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      // Limit between 10ms (fastest) and 2000ms (slowest)
      setReplaySpeed(Math.max(25, Math.min(3000, value)));
    }
  };


  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: '2rem',
        marginTop: '1rem',
        marginBottom: '1rem',
      }}
    >
      {/* Date Selector */}
      <div style={{ display: 'inline-flex', alignItems: 'center', gap: '0.5rem' }}>
        <span style={{ color: 'white', fontSize: '1rem' }}>Date</span>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: '#1f2937',
            padding: '0.5rem 0.75rem',
            borderRadius: '0.375rem',
            border: '1px solid #374151',
          }}
        >
          <input
            type="date"
            value={selectedDate || DEFAULT_DATE}
            onChange={handleDateChange}
            style={{
              backgroundColor: 'transparent',
              color: 'white',
              outline: 'none',
              border: 'none',
              fontSize: '0.875rem',
            }}
            max={new Date().toISOString().split('T')[0]}
          />
        </div>
      </div>

      {/* Speed Selector */}
      <div style={{ display: 'inline-flex', alignItems: 'center', gap: '0.5rem' }}>
        <span style={{ color: 'white', fontSize: '1rem' }}>Speed</span>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: '#1f2937',
            padding: '0.5rem 0.75rem',
            borderRadius: '0.375rem',
            border: '1px solid #374151',
          }}
        >
          <input
            type="number"
            value={replaySpeed}
            onChange={handleSpeedInput}
            style={{
              width: '4rem',
              textAlign: 'center',
              backgroundColor: 'transparent',
              color: 'white',
              outline: 'none',
              border: 'none',
              fontSize: '0.875rem',
            }}
            min="25"
            max="2000"
            step="25"
          />
          <span style={{ color: 'white', fontSize: '0.875rem', marginLeft: '0.25rem' }}>ms</span>
        </div>
      </div>

      {/* Play/Pause Button */}
      <button
        onClick={() => setIsPlaying(!isPlaying)}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0.5rem 1rem',
          borderRadius: '0.375rem',
          backgroundColor: '#1f2937',
          border: '1px solid #374151',
          color: 'white',
          cursor: 'pointer',
        }}
      >
        {isPlaying ? <Pause size={16} /> : <Play size={16} />}
      </button>
    </div>
  );
};

export default DateSelector;





// import { Calendar, Play, Pause, FastForward, Rewind } from 'lucide-react';
// import useWebSocketDataStore from '../Store/Historical_Data_Store';
// import { useEffect } from 'react';
//
// const DateSelector = ({ onDateChange }) => {
//   const DEFAULT_DATE = '2024-01-02'; // January 2nd, 2024
//
//   const {
//     sendHistoricalDataRequest,
//     isPlaying,
//     replaySpeed,
//     setIsPlaying,
//     setReplaySpeed,
//     resetReplay,
//     selectedDate,
//     setSelectedDate
//   } = useWebSocketDataStore();
//
//   useEffect(() => {
//     if (!selectedDate) {
//       setSelectedDate(DEFAULT_DATE);
//       if (sendHistoricalDataRequest) {
//         sendHistoricalDataRequest(DEFAULT_DATE);
//       }
//     }
//   }, [sendHistoricalDataRequest, selectedDate, setSelectedDate]);
//
//   const handleDateChange = (event) => {
//     const newDate = event.target.value;
//     setSelectedDate(newDate);
//     resetReplay();
//
//     if (sendHistoricalDataRequest) {
//       sendHistoricalDataRequest(newDate);
//     }
//
//     // Call parent callback
//     if (onDateChange) {
//       onDateChange(newDate);
//     }
//   };
//
//   const handleSpeedChange = (change) => {
//     setReplaySpeed((prev) => {
//       const newSpeed = prev + change;
//       return Math.max(0.5, Math.min(5, newSpeed)); // Keep speed between 0.5x and 5x
//     });
//   };
//
//   return (
//     <div
//       className="flex items-center justify-between px-4 py-2 bg-gray-900 border-b border-gray-800"
//       style={{ minHeight: '60px' }}
//     >
//       {/* Date Selection */}
//       <div className="flex items-center space-x-3">
//         <Calendar className="text-gray-400" size={20} />
//         <input
//           type="date"
//           value={selectedDate || DEFAULT_DATE}
//           onChange={handleDateChange}
//           className="px-3 py-1.5 bg-gray-800 text-white border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           max={new Date().toISOString().split('T')[0]} // Prevent future dates
//         />
//       </div>
//
//       {/* Replay Controls */}
//       <div className="flex items-center space-x-4">
//         {/* Speed Controls */}
//         <div className="flex items-center space-x-2">
//           <button
//             onClick={() => handleSpeedChange(-0.5)}
//             className="p-1.5 text-gray-400 hover:text-white rounded-md hover:bg-gray-800"
//           >
//             <Rewind size={18} />
//           </button>
//
//           <span className="text-gray-300 text-sm min-w-[60px] text-center">
//             {replaySpeed}x Speed
//           </span>
//
//           <button
//             onClick={() => handleSpeedChange(0.5)}
//             className="p-1.5 text-gray-400 hover:text-white rounded-md hover:bg-gray-800"
//           >
//             <FastForward size={18} />
//           </button>
//         </div>
//
//         {/* Play/Pause */}
//         <button
//           onClick={() => setIsPlaying(!isPlaying)}
//           className="px-4 py-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center space-x-2"
//         >
//           {isPlaying ? (
//             <>
//               <Pause size={18} />
//               <span>Pause</span>
//             </>
//           ) : (
//             <>
//               <Play size={18} />
//               <span>Play</span>
//             </>
//           )}
//         </button>
//       </div>
//     </div>
//   );
// };
//
// export default DateSelector;
import useWebSocketDataStore from '../Store/Historical_Data_Store';

export const deltaPairGetter = function(PineJS) {
  // console.log('[Delta Pair Indicator] Initializing deltaPairGetter');

  return Promise.resolve([
    {
      name: 'SPX Delta Pair',
      metainfo: {
        metainfoVersion: 53,
        id: "SPX_Delta_Pair@tv-basicstudies-1",
        description: "SPX Delta Pair",
        shortDescription: "SPX Delta Pair",
        linkedToSeries: true,
        is_price_study: false,
        format: { type: "price" },
        plots: [
          {
            id: "calls_delta",
            type: "line",
            title: "Calls Delta"
          },
          {
            id: "puts_delta",
            type: "line",
            title: "Puts Delta"
          },
          {
            id: "zero_line",
            type: "line",
            title: "Zero Line"
          }
        ],
        defaults: {
          styles: {
            calls_delta: {
              linestyle: 0,
              linewidth: 1,
              plottype: 0,  // Line plot
              trackPrice: false,
              transparency: 0,
              visible: true,
              color: "#f3d40e"  // Yellow for calls
            },
            puts_delta: {
              linestyle: 0,
              linewidth: 1,
              plottype: 0,  // Line plot
              trackPrice: false,
              transparency: 0,
              visible: true,
              color: "rgba(36,212,255,0.82)"  // Light blue for puts
            },
            zero_line: {
              linestyle: 0,
              linewidth: 1,
              color: "#ffffff",  // White zero line
              visible: true
            }
          },
          precision: 2,
          inputs: {}
        },
        styles: {
          calls_delta: { title: "Calls Delta" },
          puts_delta: { title: "Puts Delta" },
          zero_line: { title: "Zero Line" }
        },
        inputs: []
      },
      constructor: function() {
        this.main = function(ctx) {
          const time = ctx.symbol.time;
          if (!time || isNaN(time)) {
            return [null, null, null];
          }

          const store = useWebSocketDataStore.getState();
          const historicalData = store.historicalGreekData;
          const replayIndex = store.replayIndex;

          if (historicalData.length === 0) {
            return [null, null, null];
          }

          // Find the data point for the current timestamp
          const dataPoint = historicalData.find(d => d.time === time);
          if (!dataPoint) {
            return [null, null, null];
          }

          // If we're within the replay index range, return the values
          const pointIndex = historicalData.indexOf(dataPoint);
          if (pointIndex <= replayIndex) {
            // console.log('[Delta Pair Indicator] Returning value for point:', {
            //   pointIndex,
            //   replayIndex,
            //   total_call_volume_delta: dataPoint.total_call_volume_delta,
            //   total_put_volume_delta: dataPoint.total_put_volume_delta
            // });

            return [
              dataPoint.total_call_volume_delta || 0,  // calls_delta
              dataPoint.total_put_volume_delta || 0,   // puts_delta
              0                                        // zero_line
            ];
          }

          return [null, null, null];
        };
      }
    }
  ]);
};
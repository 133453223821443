// Keep track of previous options
let prevOptions = null;

const hasPlotLevelChanged = (newOptions, prevOptions, optionType, rank) => {
  if (!prevOptions || !newOptions) {
    // console.log(`[GEX Levels] No previous options for ${optionType} rank ${rank}`);
    return false;
  }

  const prevLevel = prevOptions.find(opt =>
    opt.option_type === optionType && opt.rank === rank
  );
  const newLevel = newOptions.find(opt =>
    opt.option_type === optionType && opt.rank === rank
  );

  const hasChanged = (!prevLevel && newLevel) ||
                    (!newLevel && prevLevel) ||
                    (prevLevel?.strike !== newLevel?.strike);

  if (hasChanged) {
    // console.log(`[GEX Levels] Level changed for ${optionType} rank ${rank}:`, {
    //   prevStrike: prevLevel?.strike,
    //   newStrike: newLevel?.strike
    // });
  }

  return hasChanged;
};

export const reloadChartIndicators = (currentData) => {
  if (!window.tvWidget) {
    // console.log('[GEX Levels] TV Widget not initialized');
    return;
  }

  const chart = window.tvWidget.chart();
  const studies = chart.getAllStudies();

  const currentIndicators = studies.filter(study =>
    study.name === 'SPX GEX Levels'
  );

  // console.log('[GEX Levels] Current indicators found:', currentIndicators.length);

  // Map plot IDs to their option types and ranks
  const plotConfigs = [
    { plotId: 'call_r1', type: 'call', rank: 1 },
    { plotId: 'call_r2', type: 'call', rank: 2 },
    { plotId: 'put_r1', type: 'put', rank: 1 },
    { plotId: 'put_r2', type: 'put', rank: 2 }
  ];

  // Check if any levels have changed
  let changedPlots = [];
  plotConfigs.forEach(config => {
    const plotChanged = hasPlotLevelChanged(
      currentData?.options,
      prevOptions,
      config.type,
      config.rank
    );
    if (plotChanged) {
      changedPlots.push(config.plotId);
    }
  });

  // If any levels changed, create new then remove old
  if (changedPlots.length > 0) {
    // console.log('[GEX Levels] Changes detected in plots:', changedPlots);

    currentIndicators.forEach(indicator => {
      if (indicator.name === 'SPX GEX Levels') {
        const oldIndicatorId = indicator.id;

        try {
          requestAnimationFrame(() => {
            chart.removeEntity(oldIndicatorId);
            chart.createStudy(
              indicator.name,
              false,
              false
            );
          });
        } catch (err) {
          // console.error('[GEX Levels] Error during indicator reload:', err);
        }
      }
    });
  }

  prevOptions = currentData?.options ? [...currentData.options] : null;
};






// Keep track of previous options
// let prevOptions = null;
//
// const hasPlotLevelChanged = (newOptions, prevOptions, optionType, rank) => {
//   if (!prevOptions || !newOptions) {
//     console.log(`[GEX Levels] No previous options for ${optionType} rank ${rank}`);
//     return false;
//   }
//
//   const prevLevel = prevOptions.find(opt =>
//     opt.option_type === optionType && opt.rank === rank
//   );
//   const newLevel = newOptions.find(opt =>
//     opt.option_type === optionType && opt.rank === rank
//   );
//
//   const hasChanged = (!prevLevel && newLevel) ||
//                     (!newLevel && prevLevel) ||
//                     (prevLevel?.strike !== newLevel?.strike);
//
//   if (hasChanged) {
//     console.log(`[GEX Levels] Level changed for ${optionType} rank ${rank}:`, {
//       prevStrike: prevLevel?.strike,
//       newStrike: newLevel?.strike
//     });
//   }
//
//   return hasChanged;
// };
//
// export const reloadChartIndicators = (currentData) => {
//   if (!window.tvWidget) {
//     console.log('[GEX Levels] TV Widget not initialized');
//     return;
//   }
//
//   const chart = window.tvWidget.chart();
//   const studies = chart.getAllStudies();
//
//   const currentIndicators = studies.filter(study =>
//     study.name === 'SPX GEX Levels'
//   );
//
//   console.log('[GEX Levels] Current indicators found:', currentIndicators.length);
//
//   // Map plot IDs to their option types and ranks
//   const plotConfigs = [
//     { plotId: 'call_r1', type: 'call', rank: 1 },
//     { plotId: 'call_r2', type: 'call', rank: 2 },
//     { plotId: 'put_r1', type: 'put', rank: 1 },
//     { plotId: 'put_r2', type: 'put', rank: 2 }
//   ];
//
//   // Check if any levels have changed
//   let changedPlots = [];
//   plotConfigs.forEach(config => {
//     const plotChanged = hasPlotLevelChanged(
//       currentData?.options,
//       prevOptions,
//       config.type,
//       config.rank
//     );
//     if (plotChanged) {
//       changedPlots.push(config.plotId);
//     }
//   });
//
//   // If any levels changed, create new then remove old
//   if (changedPlots.length > 0) {
//     console.log('[GEX Levels] Changes detected in plots:', changedPlots);
//
//     currentIndicators.forEach(indicator => {
//       if (indicator.name === 'SPX GEX Levels') {
//         const oldIndicatorId = indicator.id;
//
//         try {
//           // Create new indicator first
//           console.log('[GEX Levels] Creating new indicator');
//           chart.createStudy(
//             indicator.name,
//             false,
//             false
//           );
//
//           // Wait a moment for it to establish, then remove the old one
//           setTimeout(() => {
//             console.log('[GEX Levels] Removing old indicator:', oldIndicatorId);
//             chart.removeEntity(oldIndicatorId);
//           }, 0);
//
//         } catch (err) {
//           console.error('[GEX Levels] Error during indicator reload:', err);
//         }
//       }
//     });
//   }
//
//   prevOptions = currentData?.options ? [...currentData.options] : null;
// };


import useWebSocketDataStore from '../Store/Historical_Data_Store';

export const gexPairGetter = function(PineJS) {
  // console.log('[GEX Pair Indicator] Initializing gexPairGetter');

  return Promise.resolve([
    {
      name: 'SPX GEX Pair',
      metainfo: {
        metainfoVersion: 53,
        id: "SPX_GEX_Pair@tv-basicstudies-1",
        description: "SPX GEX Pair",
        shortDescription: "SPX GEX Pair",
        linkedToSeries: true,
        is_price_study: false,
        format: { type: "price" },
        plots: [
          {
            id: "calls_gex",
            type: "line",
            title: "Calls GEX"
          },
          {
            id: "puts_gex",
            type: "line",
            title: "Puts GEX"
          },
          {
            id: "zero_line",
            type: "line",
            title: "Zero Line"
          }
        ],
        defaults: {
          styles: {
            calls_gex: {
              linestyle: 0,
              linewidth: 1,
              plottype: 0,  // Line plot
              trackPrice: false,
              transparency: 0,
              visible: true,
              color: "#00ff1a"  // Green for calls
            },
            puts_gex: {
              linestyle: 0,
              linewidth: 1,
              plottype: 0,  // Line plot
              trackPrice: false,
              transparency: 0,
              visible: true,
              color: "#ff0000"  // Red for puts
            },
            zero_line: {
              linestyle: 0,
              linewidth: 1,
              color: "#ffffff",  // White zero line
              visible: true
            }
          },
          precision: 2,
          inputs: {}
        },
        styles: {
          calls_gex: { title: "Calls GEX" },
          puts_gex: { title: "Puts GEX" },
          zero_line: { title: "Zero Line" }
        },
        inputs: []
      },
      constructor: function() {
        this.main = function(ctx) {
          const time = ctx.symbol.time;
          if (!time || isNaN(time)) {
            return [null, null, null];
          }

          const store = useWebSocketDataStore.getState();
          const historicalData = store.historicalGreekData;
          const replayIndex = store.replayIndex;

          if (historicalData.length === 0) {
            return [null, null, null];
          }

          // Find the data point for the current timestamp
          const dataPoint = historicalData.find(d => d.time === time);
          if (!dataPoint) {
            return [null, null, null];
          }

          // If we're within the replay index range, return the values
          const pointIndex = historicalData.indexOf(dataPoint);
          if (pointIndex <= replayIndex) {
            // console.log('[GEX Pair Indicator] Returning value for point:', {
            //   pointIndex,
            //   replayIndex,
            //   total_call_volume_gamma: dataPoint.total_call_volume_gamma,
            //   total_put_volume_gamma: dataPoint.total_put_volume_gamma
            // });

            return [
              dataPoint.total_call_volume_gamma || 0,  // calls_gex
              dataPoint.total_put_volume_gamma || 0,   // puts_gex
              0                                        // zero_line
            ];
          }

          return [null, null, null];
        };
      }
    }
  ]);
};
import useWebSocketDataStore from '../Store/Historical_Data_Store';


export const totalGEXGetter = function(PineJS) {
  // console.log('[GEX Indicator] Initializing totalGEXGetter');

  return Promise.resolve([
    {
      name: 'SPX_Total_GEX',
      metainfo: {
        metainfoVersion: 53,
        id: "SPX_Total_GEX@tv-basicstudies-1",
        description: "SPX Total GEX",
        shortDescription: "SPX Total GEX",
        linkedToSeries: true,
        is_price_study: false,
        format: { type: "price" },
        plots: [
          {
            id: "net_gex",
            type: "line",
            title: "Net GEX",
            trackPrice: true,
            transparency: 70,
            fillGaps: true
          },
          {
            id: "zero_line",
            type: "line",
            title: "Zero Line"
          }
        ],
        defaults: {
          styles: {
            net_gex: {
              linestyle: 0,
              linewidth: 1,
              plottype: 8,
              trackPrice: false,
              transparency: 60,
              visible: true,
              color: "#73a9e7",
              color2: "#FF4500",
            },
            zero_line: {
              linestyle: 0,
              linewidth: 1,
              color: "#ffffff",
              visible: true
            }
          },
          precision: 2,
          inputs: {}
        },
        styles: {
          net_gex: { title: "Net GEX", histogramBase: 8 },
          zero_line: { title: "Zero Line" }
        },
        inputs: []
      },
      constructor: function() {
        let initialized = false;

        this.main = function(ctx) {
          const time = ctx.symbol.time;
          if (!time || isNaN(time)) {
            return [null, null];
          }

          const store = useWebSocketDataStore.getState();
          const historicalData = store.historicalGreekData;
          const replayIndex = store.replayIndex;

          // console.log('[GEX Indicator] Current state:', {
          //   time,
          //   replayIndex,
          //   historicalDataLength: historicalData.length,
          //   initialized
          // });

          if (historicalData.length === 0) {
            return [null, null];
          }

          // Find the data point for the current timestamp
          const dataPoint = historicalData.find(d => d.time === time);
          if (!dataPoint) {
            return [null, null];
          }

          // If we're within the replay index range, return the value
          const pointIndex = historicalData.indexOf(dataPoint);
          if (pointIndex <= replayIndex) {
            // console.log('[GEX Indicator] Returning value for point:', {
            //   pointIndex,
            //   replayIndex,
            //   total_gex: dataPoint.total_gex
            // });
            return [dataPoint.total_gex || 0, 0];
          }

          return [null, null];
        };
      }
    }
  ]);
};









import useWebSocketDataStore from '../Store/Historical_Data_Store';

export const dynamicLevelsGetter = function(PineJS) {
  // console.log('[Dynamic Levels Indicator] Initializing dynamicLevelsGetter');

  return Promise.resolve([
    {
      name: 'SPX Dynamic Levels',
      metainfo: {
        metainfoVersion: 53,
        id: "SPX_Dynamic_Levels@tv-basicstudies-1",
        description: "SPX Dynamic Levels",
        shortDescription: "SPX Dynamic Levels",
        format: { type: "price" },
        linkedToSeries: true,
        is_price_study: true,
        plots: [
          { id: "ddh_level", type: "line" },
          { id: "ddl_level", type: "line" }
        ],
        defaults: {
          styles: {
            ddh_level: {
              linestyle: 0,
              linewidth: 1,
              transparency: 0,
              color: "#ffffff",
            },
            ddl_level: {
              linestyle: 0,
              linewidth: 1,
              transparency: 0,
              color: "#ffffff",
            }
          },
        },
        styles: {
          ddh_level: { title: "Dynamic Daily High" },
          ddl_level: { title: "Dynamic Daily Low" }
        },
        inputs: [],
      },
      constructor: function() {
        this._debugMode = true;

        this.init = function(ctx) {
          this._context = ctx;
        };

        this.main = function(ctx) {
          try {
            const store = useWebSocketDataStore.getState();
            const { historicalGreekData, replayIndex } = store;

            // Get the current point in time based on replay index
            const currentData = historicalGreekData.filter((d, index) => index <= replayIndex);

            // Get the most recent data point
            const latestPoint = currentData[currentData.length - 1];

            // Get current dynamic levels
            const ddh = latestPoint?.dynamicLevels?.ddh?.strike || NaN;
            const ddl = latestPoint?.dynamicLevels?.ddl?.strike || NaN;

            // Return the same levels for any point in time
            return [ddh, ddl];
          } catch (err) {
            console.error('[Dynamic Levels] Error in main:', err);
            return [NaN, NaN];
          }
        };

        this.destroy = function() {
          // No cleanup needed
        };
      }
    }
  ]);
};


















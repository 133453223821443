// App.js
import React from 'react';
import './App.css';
import HistoricalClient from './Components/historical_client.js';
import HistoricalFlowsDashboard from './Components/Historical_Flows_Dashboard';

function App() {
  console.log('App rendering');

  return (
    <div className="App">
      <HistoricalClient />
      <HistoricalFlowsDashboard />
    </div>
  );
}

export default App;












import { create } from 'zustand';
import useStreamingStore from "./Historical_Streaming_Store";
import { reloadChartIndicators } from '../Components/tradingViewUtils';

const alignDatasets = (greekData, optionsData) => {
  // console.log('Aligning datasets:', {
  //   greekDataLength: greekData.length,
  //   optionsDataLength: optionsData.length,
  //   firstGreekTimestamp: greekData[0]?.time || null,
  //   firstOptionTimestamp: optionsData[0]?.time || null,
  // });

  const alignedData = [];

  // Group processed options by time for quick lookup
  const optionsByTime = new Map();
  optionsData.forEach(opt => {
    if (!optionsByTime.has(opt.time)) {
      optionsByTime.set(opt.time, []);
    }
    optionsByTime.get(opt.time).push(opt);
  });

  // Align with Greek data
  greekData.forEach(greekPoint => {
    const options = optionsByTime.get(greekPoint.time) || [];

    if (options.length === 4) { // Must have exactly 4 options (2 calls, 2 puts)
      alignedData.push({
        ...greekPoint,
        options: options.sort((a, b) => {
          // Sort by type first (calls before puts), then by rank
          if (a.option_type !== b.option_type) {
            return a.option_type === 'call' ? -1 : 1;
          }
          return a.rank - b.rank;
        })
      });
    }
  });

  // console.log('Alignment complete:', {
  //   totalAlignedPoints: alignedData.length,
  //   samplePoint: alignedData[0]
  // });

  return alignedData;
};

const useWebSocketDataStore = create((set, get) => ({
  historicalGreekData: [],
  historicalOptionsData: [],
  alignedData: [],
  replayIndex: 0,
  isPlaying: false,
  replaySpeed: 250,
  selectedDate: null,
  replayInterval: null,
  sendHistoricalDataRequest: null,

  setHistoricalData: ({ processedGreekData, processedOptionsData }) => {
      // Send first point to initialize chart display
      if (processedGreekData.length > 0) {
        useStreamingStore.getState().setInitialGreekData(processedGreekData[0]);
      }

      const alignedData = alignDatasets(processedGreekData, processedOptionsData);
      set({
        historicalGreekData: processedGreekData,
        historicalOptionsData: processedOptionsData,
        alignedData,
        replayIndex: 0
      });
  },

  appendData: (newGreekData) => {
      // Send first point to initialize chart display
      if (newGreekData.length > 0) {
        useStreamingStore.getState().setInitialGreekData(newGreekData[0]);
      }

      const state = get();
      set({ historicalGreekData: newGreekData });

      if (state.historicalOptionsData.length > 0) {
        const alignedData = alignDatasets(newGreekData, state.historicalOptionsData);
        set({
          alignedData,
          replayIndex: 0
        });
      }
  },

  setOptionsData: (newOptionsData) => {
    const state = get();
    set({ historicalOptionsData: newOptionsData });

    if (state.historicalGreekData.length > 0) {
      const alignedData = alignDatasets(state.historicalGreekData, newOptionsData);
      set({ alignedData });
    }
  },

  setSendHistoricalDataRequest: (requestFn) => {
    set({ sendHistoricalDataRequest: requestFn });
  },

  setIsPlaying: (playing) => {
      const state = get();
      // console.log('[Replay Speed Debug]', {
      //     replaySpeed: state.replaySpeed,
      //     interval: state.replayInterval,
      //     isPlaying: state.isPlaying,
      //     currentIndex: state.replayIndex,
      //     timestamp: new Date().toISOString()
      // });

      if (playing && !state.isPlaying) {
        if (state.replayInterval) {
          clearInterval(state.replayInterval);
        }

        const interval = setInterval(() => {
          const currentState = get();
          const now = new Date().toISOString();
          // console.log(`[Replay Tick] at ${now}, speed: ${currentState.replaySpeed}ms, index: ${currentState.replayIndex}`);

          if (currentState.replayIndex < currentState.alignedData.length - 1) {
              set({ replayIndex: currentState.replayIndex + 1 });
              // Get the current data point
              const currentData = currentState.alignedData[currentState.replayIndex + 1];
              reloadChartIndicators(currentData);
            } else {
              // console.log('[Replay Complete]');
              clearInterval(interval);
              set({ isPlaying: false, replayInterval: null });
            }
          }, state.replaySpeed);

        set({ isPlaying: true, replayInterval: interval });
      } else if (!playing && state.isPlaying) {
        // console.log('[Replay Stopped]');
        if (state.replayInterval) {
          clearInterval(state.replayInterval);
        }
        set({ isPlaying: false, replayInterval: null });
      }
    },

  setReplaySpeed: (speed) => {
    const state = get();
    set({ replaySpeed: speed });
    if (state.isPlaying) {
      state.setIsPlaying(false);
      state.setIsPlaying(true);
    }
  },

  setSelectedDate: (date) => {
    const state = get();
    if (state.replayInterval) {
      clearInterval(state.replayInterval);
    }
    useStreamingStore.getState().resetStreaming();
    window.tvWidget?.remove();
    set({
      selectedDate: date,
      replayIndex: 0,
      isPlaying: false,
      replayInterval: null,
      historicalGreekData: [],
      historicalOptionsData: [],
      alignedData: []
    });
  },

  resetReplay: () => {
      const state = get();
      if (state.replayInterval) {
        clearInterval(state.replayInterval);
      }
      // Reset streaming store
      useStreamingStore.getState().resetStreaming();
      // Reset all replay-related state
      set({
        replayIndex: 0,
        isPlaying: false,
        replayInterval: null
      });
      // Re-trigger initial data point
      const historicalData = state.historicalGreekData;
      if (historicalData.length > 0) {
        useStreamingStore.getState().setInitialGreekData(historicalData[0]);
      }
  }
}));

export default useWebSocketDataStore;
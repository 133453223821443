import { create } from 'zustand';
import useWebSocketDataStore from './Historical_Data_Store';

const useStreamingStore = create((set, get) => ({
  currentGreekData: null,
  currentOptionsData: [],
  lastBarTime: null,

  setInitialGreekData: (initialGreekData) => {
    if (!initialGreekData) return;

    // console.log('[Streaming Store] Setting initial data point:', {
    //   time: initialGreekData.time,
    //   open: initialGreekData.open,
    //   high: initialGreekData.high,
    //   low: initialGreekData.low,
    //   close: initialGreekData.close,
    //   total_delta: initialGreekData.total_delta,
    //   total_gamma: initialGreekData.total_call_volume_gamma + initialGreekData.total_put_volume_gamma,
    //   total_theta: initialGreekData.total_theta,
    //   total_vega: initialGreekData.total_vega,
    //   total_gex: initialGreekData.total_gex,
    // });

    set({
      currentGreekData: initialGreekData,
      currentOptionsData: [], // No options data for initial point
      lastBarTime: initialGreekData.time
    });
  },

  subscribeToHistoricalStore: () => {
    useWebSocketDataStore.subscribe((state) => {
      const { historicalGreekData, historicalOptionsData, replayIndex, isPlaying } = state;

      if (!historicalGreekData || !historicalGreekData.length) return;

      const currentGreekData = historicalGreekData[replayIndex];

      if (currentGreekData) {
        const currentTime = currentGreekData.time;
        const lastTime = get().lastBarTime;

        if (currentTime !== lastTime) {
          const timeForOptionsComparison = Math.floor(new Date(currentTime).getTime() / 1000);

          const relevantOptionsData = historicalOptionsData.filter(
            opt => opt.timestamp === timeForOptionsComparison
          );

          // console.log('[Streaming Store] Processing new data point:', {
          //   index: replayIndex,
          //   time: currentTime,
          //   open: currentGreekData.open,
          //   high: currentGreekData.high,
          //   low: currentGreekData.low,
          //   close: currentGreekData.close,
          //   total_delta: currentGreekData.total_delta,
          //   total_gamma: currentGreekData.total_call_volume_gamma + currentGreekData.total_put_volume_gamma,
          //   total_theta: currentGreekData.total_theta,
          //   total_vega: currentGreekData.total_vega,
          //   total_gex: currentGreekData.total_gex,
          //   hasOptionsData: relevantOptionsData.length > 0
          // });

          set({
            currentGreekData,
            currentOptionsData: relevantOptionsData,
            lastBarTime: currentTime
          });
        }
      }
    });
  },

  resetStreaming: () => {
    // console.log('[Streaming Store] Resetting state');
    set({
      currentGreekData: null,
      currentOptionsData: [],
      lastBarTime: null
    });
  }
}));

const store = useStreamingStore;

// Move subscription initialization to after export
setTimeout(() => {
  store.getState().subscribeToHistoricalStore();
}, 0);

export default store;